'use client';

import { memo, useEffect, useMemo, useState } from 'react';

import { sendGTMEvent } from '@next/third-parties/google';
import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { useLocale } from '@string/hooks/use-locale/use-locale';
import type {
  BundleEnriched,
  BundleVariantEnriched,
  ProductColor,
  ProductDetailFields,
} from '@string/types';
import type { AssetStoryblok } from '@string/types/generated-storyblok-types';
import { formatPrice } from '@string/utils/price/format-price';
import { getProductColorName } from '@string/utils/variants/get-product-color-name';

import {
  ButtonLarge,
  ButtonLargeLink,
} from '../../../button-large/button-large';
import { Heading } from '../../../heading/heading';
import { HeroPdpContainer } from '../../../hero-pdp-container/hero-pdp-container';
import { ProductDetailColors } from '../../../product-detail-colors/product-detail-colors';
import { ProductDetailImage } from '../../../product-detail-image/product-detail-image';
import { BundleSpecifications } from '../bundle-specifications/bundle-specifications';
import stylesShared from '../shared.module.scss';

import styles from './bundle-details.module.scss';

interface ProductDetailsProps {
  details: BundleEnriched;
  pdp: ProductDetailFields;
  colorLabel?: string;
  image?: AssetStoryblok;
}

export const BundleDetails: React.FC<ProductDetailsProps> = memo(
  ({ details, pdp, colorLabel }) => {
    const { variants } = details;
    const locale = useLocale();

    const {
      loading,
      addLineItemsToCart,
      addToCartLabel,
      priceLabel,
      openInByo,
    } = useCart();
    const [selected, setSelected] = useState<BundleVariantEnriched | null>(
      variants?.[0] || null
    );

    const byoHref = useMemo(() => {
      if (!selected?.design_id) {
        return '';
      }
      return `/${locale.localeTag}/design-assistance/string-planner?byo_design_id=${selected.design_id}#byo`;
    }, [locale, selected]);

    const title = useMemo(
      () => selected?.title || details?.title || '',
      [selected]
    );

    const variantImage = useMemo(
      () => selected?.product_image?.url,
      [selected]
    );
    const variantImageAlt = selected?.product_image?.description || title;

    const price = useMemo(() => {
      return [
        !locale.isShopEnabled && priceLabel,
        formatPrice(selected?.price, locale.localeTag),
      ]
        .filter(Boolean)
        .join(' ');
    }, [selected?.price, locale.localeTag]);

    const selectedColor: ProductColor | null = useMemo(() => {
      if (!selected?.primary_color) {
        return null;
      }
      return {
        primary_color: selected.primary_color,
        secondary_color: selected.secondary_color || null,
      };
    }, [selected]);

    const selectedColorName = useMemo(
      () => getProductColorName(selectedColor),
      [selectedColor]
    );

    useEffect(() => {
      if (selected) {
        const newUrl = `${window.location.origin}/${locale.localeTag}/products/system/combinations/${details.tag}/${selected.key}`;
        window.history.replaceState(null, '', newUrl);
      }
    }, [selected]);

    const onAddToCartClick = async () => {
      const lineItems = selected?.articles.map(({ big_commerce, amount }) => {
        if (!big_commerce) {
          return;
        }
        return {
          product_id: big_commerce.big_commerce_product_id,
          quantity: amount,
          variant_id: big_commerce.big_commerce_id,
        };
      });
      if (lineItems?.length) {
        const contents = selected?.articles
          .map(
            (item) =>
              item?.big_commerce && {
                id: [item.big_commerce.big_commerce_id],
                item_price: item.price?.price_inc_vat || null,
                quantity: item.amount,
              }
          )
          .filter(Boolean);

        const bigCommerceIds = lineItems
          .map((item) => item && `${item.product_id}-${item.variant_id}`)
          .filter(Boolean);

        sendGTMEvent({
          bigCommerceIds,
          content_type: 'product',
          contents,
          currency: selected?.price?.currency.name || locale.currency,
          event: 'AddToCartBundle',
        });
        addLineItemsToCart(lineItems);
      }
    };

    function onColorChange(key: string) {
      setSelected((current) => {
        return (
          variants?.find((item) => {
            return item.key === key;
          }) || current
        );
      });
    }

    const figure = useMemo(() => {
      const image = details?.media?.find((item) => item?.url);

      return {
        alt: image?.description || details?.title || '',
        src: image?.url || '',
      };
    }, [details]);

    const extra = (
      <BundleSpecifications
        // colors={colors}
        colors={[]}
        pdp={pdp}
        productDescription={details.description}
        variant={selected}
      />
    );

    return (
      <HeroPdpContainer alt={figure.alt} extra={extra} src={figure.src}>
        <ProductDetailImage alt={variantImageAlt} src={variantImage} priority />
        <Heading as="h1" className={clsx(stylesShared.header)}>
          {title}
        </Heading>
        <span className={clsx('text__body', stylesShared.price)}>{price}</span>
        <ProductDetailColors
          callback={onColorChange}
          className={styles.colors}
          label={colorLabel}
          selected={selectedColorName}
          variants={details.variants}
        />
        {/* <div className={clsx(styles.container)}>
        <form className={clsx(stylesShared.variant__selector)}>
          <fieldset>
            <legend className={clsx('visually-hidden')}>{colorLabel}</legend>
            {colors?.map(colorMapper)}
          </fieldset>
        </form>
        <div />
      </div> */}
        <div className={clsx(styles.actions)}>
          {byoHref && (
            <ButtonLargeLink href={byoHref} variant="dark" wide>
              {openInByo}
            </ButtonLargeLink>
          )}
          <ButtonLarge
            disabled={loading}
            onClick={onAddToCartClick}
            variant="filled"
            wide
          >
            {addToCartLabel}
          </ButtonLarge>
        </div>
      </HeroPdpContainer>
    );
  }
);
