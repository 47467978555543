export const formatDate = (
  dateString: string | undefined | null,
  localeTag: string,
  dateStyle: 'full' | 'long' | 'medium' | 'short' | undefined = 'long'
): string => {
  if (!dateString) {
    return '';
  }
  if (!localeTag || !/\w{2,3}-\w{2,3}/.test(localeTag)) {
    return dateString;
  }
  const date = new Date(dateString);
  const locale = localeTag.replace(/-kor$/, '-kr');

  const formatter = new Intl.DateTimeFormat(locale, { dateStyle });
  return formatter.format(date);
};
