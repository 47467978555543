import { useMemo } from 'react';

import {
  MediaObject,
  ProductEnriched,
  ProductVariantEnriched,
} from '@string/types';
import { getProductColorName } from '@string/utils';

function urlFilter({ url }: MediaObject) {
  return !!url;
}

function orientationFilter({ url, orientation }: MediaObject) {
  return !!url && orientation === 'portrait';
}

function getSelectedImage(
  selected: ProductVariantEnriched,
  variants: ProductVariantEnriched[]
) {
  let image: MediaObject | undefined = undefined;

  // Try to get an exact match first
  if (selected) {
    image = selected?.media
      ?.filter(orientationFilter)
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look for same variant, but not matching orientation
  if (selected && !image) {
    image = selected?.media?.filter(urlFilter)?.filter(Boolean)?.reverse()?.[0];
  }
  // Look for secondary articles in same variant
  if (selected && !image) {
    image = selected?.articles
      ?.flatMap(({ media }) => media?.filter(orientationFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look for secondary articles in same variant, but not matching orientation
  if (selected && !image) {
    image = selected?.articles
      ?.flatMap(({ media }) => media?.filter(urlFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look in other variants of same color
  if (selected && !image) {
    image = variants
      ?.filter(
        (variant) =>
          getProductColorName(variant) === getProductColorName(selected)
      )
      ?.flatMap(({ media }) => media?.filter(orientationFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look in other variants of same color, but not matching orientation
  if (selected && !image) {
    image = variants
      ?.filter(
        (variant) =>
          getProductColorName(variant) === getProductColorName(selected)
      )
      ?.flatMap(({ media }) => media?.filter(urlFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look in other variants of same primary color
  if (selected && !image) {
    image = variants
      ?.filter(
        (variant) =>
          getProductColorName({
            ...variant,
            secondary_color: null,
          }) ===
          getProductColorName({
            ...selected,
            secondary_color: null,
          })
      )
      ?.flatMap(({ media }) => media?.filter(orientationFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look in other variants of same primary color, but not matching orientation
  if (selected && !image) {
    image = variants
      ?.filter(
        (variant) =>
          getProductColorName({
            ...variant,
            secondary_color: null,
          }) ===
          getProductColorName({
            ...selected,
            secondary_color: null,
          })
      )
      ?.flatMap(({ media }) => media?.filter(urlFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  return image;
}

export const getProductFigure = (
  product: ProductEnriched,
  selected?: ProductVariantEnriched | null
) => {
  const { variants = [] } = product || {};
  let image: MediaObject | undefined = undefined;

  // Try get image based on selected variant first
  if (selected) {
    image = getSelectedImage(selected, variants);
  }
  // Look in all other variants
  if (!image) {
    image = variants
      ?.flatMap(({ media }) => media?.filter(orientationFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }
  // Look in all other variants, but not matching orientation
  if (!image) {
    image = variants
      ?.flatMap(({ media }) => media?.filter(urlFilter))
      ?.filter(Boolean)
      ?.reverse()?.[0];
  }

  return {
    alt: image?.description || selected?.title || product?.title || '',
    src: image?.url || '',
  };
};

export const useProductFigure = (
  product: ProductEnriched,
  selected?: ProductVariantEnriched | null
) => {
  return useMemo(() => {
    return getProductFigure(product, selected);
  }, [selected, product]);
};
