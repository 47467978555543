'use client';

import { memo, useId } from 'react';

import clsx from 'clsx';

import { ProductDetailFields } from '@string/types';
import type { BundleVariantEnriched } from '@string/types/portal-types/bundle-variant';
import type { BundleVariantArticle } from '@string/types/portal-types/bundle-variant-articles';
import type { Color } from '@string/types/portal-types/color';
import { formatPrice } from '@string/utils/price/format-price';

import { Accordion } from '../../../accordion/accordion';
import { AccordionItem } from '../../../accordion-item/accordion-item';

import styles from './bundle-specifications.module.scss';

interface BundleSpecificationsProps {
  variant: BundleVariantEnriched | null;
  productDescription?: string;
  pdp: ProductDetailFields;
  colors?: Color[];
}

export const BundleSpecifications: React.FC<BundleSpecificationsProps> = memo(
  ({ variant, productDescription, pdp }) => {
    const { article_list_label, name, price, article_number, amount } = pdp;

    const uid = useId();
    function rowMapper(item?: BundleVariantArticle) {
      return (
        item?.id && (
          <tr key={`Table-${uid}-Row-${item.id}`} className={styles.tr}>
            <td className={styles.td} title={name}>
              {item.name}
            </td>
            <td className={styles.td} title={article_number}>
              {item.article_number}
            </td>
            <td className={styles.td} title={price}>
              {formatPrice(item.price, 'sv-SE')}
            </td>
            <td className={styles.td} title={amount}>
              {item.amount}
            </td>
          </tr>
        )
      );
    }

    return (
      <div>
        <p className={clsx(styles.margin)}>{productDescription}</p>
        <Accordion type="multiple">
          <AccordionItem
            className={styles.item}
            trigger={article_list_label}
            value="bundle-specifications"
          >
            <table className={clsx('text__body', styles.table)}>
              <thead className={clsx(styles.thead, 'visually-hidden')}>
                <tr className={styles.tr}>
                  <th className={styles.th}>{name}</th>
                  <th className={styles.th}>{article_number}</th>
                  <th className={styles.th}>{price}</th>
                  <th className={styles.th}>{amount}</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {variant?.articles?.map(rowMapper)}
              </tbody>
            </table>
          </AccordionItem>
        </Accordion>
      </div>
    );
  }
);
