'use client';

import { memo, useEffect, useMemo, useState } from 'react';

import dynamic from 'next/dynamic';

import { sendGTMEvent } from '@next/third-parties/google';
import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { useProductFigure } from '@string/hooks/use-product-figure/use-product-figure';
import { useProductImage } from '@string/hooks/use-product-image/use-product-image';
import type {
  ProductDetailFields,
  ProductEnriched,
  ProductVariantDimensions,
  ProductVariantEnriched,
  SiteLocale,
} from '@string/types';
import type { AssetStoryblok } from '@string/types/generated-storyblok-types';
import { formatDate, formatPrice, getProductColorName } from '@string/utils';
import { replacePlaceholders } from '@string/utils/string/replace-placeholders';

import { ActionsPDP } from '../../../actions-pdp/actions-pdp';
import {
  ButtonLarge,
  ButtonLargeLink,
} from '../../../button-large/button-large';
import { Heading } from '../../../heading/heading';
import { HeroPdpContainer } from '../../../hero-pdp-container/hero-pdp-container';
import { ProductDetailColors } from '../../../product-detail-colors/product-detail-colors';
import { ProductDetailImage } from '../../../product-detail-image/product-detail-image';
import { ProductDetailPrice } from '../../../product-detail-price/product-detail-price';
import { ProductSpecifications } from '../product-specifications/product-specifications';

import styles from './product-details.module.scss';

const InputDropdownGroup = dynamic(() =>
  import('../../../input-dropdown-group/input-dropdown-group').then(
    (mod) => mod.InputDropdownGroup
  )
);
const InputSelect = dynamic(() =>
  import('../../../input-select/input-select').then((mod) => mod.InputSelect)
);

interface ProductDetailsProps {
  details: ProductEnriched;
  locale: SiteLocale;
  pdp: ProductDetailFields;
  colorLabel: string;
  dimensionLabel: string;
  packLabel: string;
  image?: AssetStoryblok;
  packLabelSingular?: string;
  packLabelPlural?: string;
  dimensionsLabel?: string;
  outOfStockLabel?: string;
  outOfStockShort?: string;
  outOfStockMessage?: string;
}

function getDimensionsKey(
  dimensions: ProductVariantDimensions | null | undefined
): string {
  if (!dimensions) {
    return '';
  }
  return Object.entries(dimensions)
    .map(([key, value]) => {
      if (key !== 'weight' && typeof value === 'number') {
        return `${key.substring(0, 1)}${value}`;
      }
    })
    .filter(Boolean)
    .sort()
    .join('-');
}

function getDimensionsValue(
  dimensions: ProductVariantDimensions | null | undefined,
  dimensionsLabel = 'W%w x H%h x D%d'
) {
  const [width, height, depth] = dimensionsLabel
    .split('x')
    .map((part) => part.trim());
  const parts: string[] = [];
  if (dimensions?.width) {
    parts.push(
      replacePlaceholders<string>(width, [['%w', dimensions.width.toString()]])
    );
  }
  if (dimensions?.height) {
    parts.push(
      replacePlaceholders<string>(height, [
        ['%h', dimensions.height.toString()],
      ])
    );
  }
  if (dimensions?.depth) {
    parts.push(
      replacePlaceholders<string>(depth, [['%d', dimensions.depth.toString()]])
    );
  }
  return parts.join(' x ');
}

function getPackSizeKey(variant?: ProductVariantEnriched | null) {
  return variant?.object_count?.toString() || '';
}

export const ProductDetails: React.FC<ProductDetailsProps> = memo(
  ({
    details,
    locale,
    pdp,
    colorLabel,
    dimensionLabel,
    packLabel,
    packLabelSingular,
    packLabelPlural,
    outOfStockLabel,
    outOfStockMessage,
    outOfStockShort,
  }) => {
    const { variants, description } = details;

    const {
      loading,
      addLineItemsToCart,
      addToCartLabel,
      findStoreHref,
      priceLabel,
    } = useCart();

    const [selected, setSelected] = useState<ProductVariantEnriched | null>(
      variants.find((variant) => variant.key === details.key) || null
    );

    const getPackSizeLabel = (size: number) => {
      const label =
        size === 1
          ? packLabelSingular
          : replacePlaceholders<string>(packLabelPlural || '', [
              ['%n', `${size}`],
            ]);
      return label || `${size}`;
    };

    const {
      colors,
      dimensions,
      hasDimensions,
      hasPackSizes,
      packSizes,
      selectedColor,
      selectedColorName,
      selectedDimension,
      selectedPackSize,
      title,
    } = useMemo(() => {
      const colorKey = getProductColorName(selected);
      const dimensionsKey = getDimensionsKey(selected?.dimensions);
      const packSizeKey = getPackSizeKey(selected);

      const newPackSizesRaw: [string, string][] = [];
      variants
        .filter((variant) => {
          return (
            dimensionsKey === getDimensionsKey(variant?.dimensions) &&
            colorKey === getProductColorName(variant) &&
            !!variant?.object_count
          );
        })
        .sort((a, b) => a.object_count - b.object_count)
        .forEach((variant) => {
          const key = getPackSizeKey(variant);
          if (!key) {
            return;
          }
          const value: [string, string] = [
            key,
            getPackSizeLabel(variant.object_count),
          ];
          newPackSizesRaw.push(value);
        });

      const newDimensionsRaw: [string, string][] = [];
      variants
        .filter((variant) => {
          return (
            packSizeKey === getPackSizeKey(variant) &&
            colorKey === getProductColorName(variant) &&
            !!variant?.dimensions
          );
        })
        .sort((a, b) => {
          if (a.dimensions?.width && b.dimensions?.width) {
            return a.dimensions.width - b.dimensions.width;
          }
          if (a.dimensions?.height && b.dimensions?.height) {
            return a.dimensions.height - b.dimensions.height;
          }
          if (a.dimensions?.depth && b.dimensions?.depth) {
            return a.dimensions.depth - b.dimensions.depth;
          }
          return 0;
        })
        .map((variant) => {
          const key = getDimensionsKey(variant.dimensions);
          if (!key) {
            return;
          }
          const value: [string, string] = [
            key,
            getDimensionsValue(variant.dimensions),
          ];

          newDimensionsRaw.push(value);

          return value;
        });

      return {
        colors: variants.filter(
          (variant) =>
            dimensionsKey === getDimensionsKey(variant?.dimensions) &&
            packSizeKey === getPackSizeKey(variant)
        ),
        dimensions: new Map(newDimensionsRaw),
        hasDimensions: newDimensionsRaw.length > 1,
        hasPackSizes: newPackSizesRaw.length > 1,
        packSizes: new Map(newPackSizesRaw),
        selectedColor: !selected?.primary_color
          ? null
          : {
              primary_color: selected.primary_color,
              secondary_color: selected.secondary_color || null,
            },
        selectedColorName: colorKey,
        selectedDimension: dimensionsKey,
        selectedPackSize: packSizeKey,
        title: selected?.title || details?.title || '',
      };
    }, [selected]);

    const selectFromPackSize = (size: string) => {
      setSelected((current) => {
        const all = variants.find((item) => {
          const color = getProductColorName(item);
          const dimensions = getDimensionsKey(item.dimensions);
          return (
            dimensions === selectedDimension &&
            color === getProductColorName(selectedColor) &&
            item.object_count?.toString() === size
          );
        });
        if (all) {
          return all;
        }
        const dimensionsAndPackSize = variants.find((item) => {
          const dimensions = getDimensionsKey(item.dimensions);
          return (
            dimensions === selectedDimension &&
            item.object_count?.toString() === size
          );
        });
        if (dimensionsAndPackSize) {
          return dimensionsAndPackSize;
        }
        const colorAndPackSize = variants.find((item) => {
          const color = getProductColorName(item);
          return (
            color === getProductColorName(selectedColor) &&
            item.object_count?.toString() === size
          );
        });
        if (colorAndPackSize) {
          return colorAndPackSize;
        }

        return (
          variants.find((item) => {
            return item.object_count?.toString() === size;
          }) || current
        );
      });
    };

    const selectFromDimension = (dimension: string) => {
      const {
        depth = null,
        height = null,
        width = null,
      }: Record<string, number | null> = Object.fromEntries(
        dimension.split('-').map((part) => {
          const key = {
            d: 'depth',
            h: 'height',
            w: 'width',
          }[part.substring(0, 1)];
          const value = parseFloat(part.substring(1));
          return [key, value];
        })
      );

      const key = getDimensionsKey({
        depth,
        height,
        weight: null,
        width,
      });

      setSelected((current) => {
        const all = variants.find((item) => {
          const color = getProductColorName(item);
          const packSize = item.object_count?.toString();
          const dimensions = getDimensionsKey(item.dimensions);
          return (
            color === getProductColorName(selectedColor) &&
            packSize === selectedPackSize &&
            dimensions === key
          );
        });
        if (all) {
          return all;
        }
        const colorAndDimension = variants.find((item) => {
          const color = getProductColorName(item);
          const dimensions = getDimensionsKey(item.dimensions);
          return (
            color === getProductColorName(selectedColor) && dimensions === key
          );
        });
        if (colorAndDimension) {
          return colorAndDimension;
        }
        const packSizeAndDimension = variants.find((item) => {
          const packSize = item.object_count?.toString();
          const dimensions = getDimensionsKey(item.dimensions);
          return packSize === selectedPackSize && dimensions === key;
        });
        if (packSizeAndDimension) {
          return packSizeAndDimension;
        }
        return (
          variants.find(
            ({ dimensions }) => getDimensionsKey(dimensions) === key
          ) || current
        );
      });
    };

    const figure = useProductFigure(details, selected);
    const productImage = useProductImage(details, selected);

    const price = useMemo(() => {
      if (!selected?.price?.price_inc_vat) {
        return '';
      }

      return [
        !locale.isShopEnabled && priceLabel,
        formatPrice(selected.price, locale.localeTag),
      ]
        .filter(Boolean)
        .join(' ');
    }, [selected, variants]);

    useEffect(() => {
      if (selected) {
        const newUrl = `${window.location.origin}/${locale.localeTag}/products/${selected.family}/${selected.category}/${selected.key}`;
        window.history.replaceState(null, '', newUrl);
      }
    }, [selected, locale]);

    const onAddToCartClick = () => {
      const lineItems = selected?.articles.map(({ big_commerce, amount }) => {
        if (!big_commerce) {
          return;
        }
        return {
          product_id: big_commerce.big_commerce_product_id,
          quantity: amount,
          variant_id: big_commerce.big_commerce_id,
        };
      });

      if (lineItems?.length) {
        const contents = selected?.articles
          .map(
            (item) =>
              item?.big_commerce && {
                id: [item.big_commerce.big_commerce_id],
                item_price: item.price?.price_inc_vat || null,
                quantity: item.amount,
              }
          )
          .filter(Boolean);

        const bigCommerceIds = lineItems
          .map((item) => item && `${item.product_id}-${item.variant_id}`)
          .filter(Boolean);

        sendGTMEvent({
          bigCommerceIds,
          content_type: 'product',
          contents,
          currency: selected?.price?.currency.name || locale.currency,
          event: 'AddToCart',
        });
        addLineItemsToCart(lineItems, title);
      }
    };

    function selectFromColor(key: string) {
      const variant = variants?.find((item) => item.key === key);
      const colorName = getProductColorName(variant);
      setSelected((current) => {
        const all = variants.find((item) => {
          const color = getProductColorName(item);
          const packSize = item.object_count?.toString();
          const dimensions = getDimensionsKey(item.dimensions);
          return (
            color === colorName &&
            packSize === selectedPackSize &&
            dimensions === selectedDimension
          );
        });

        if (all) {
          return all;
        }
        const colorAndPackSize = variants.find((item) => {
          const color = getProductColorName(item);
          const packSize = item.object_count?.toString();

          return color === colorName && packSize === selectedPackSize;
        });
        if (colorAndPackSize) {
          return colorAndPackSize;
        }
        const colorAndDimensions = variants.find((item) => {
          const color = getProductColorName(item);

          const dimensions = getDimensionsKey(item.dimensions);
          return color === colorName && dimensions === selectedDimension;
        });
        if (colorAndDimensions) {
          return colorAndDimensions;
        }

        return (
          variants.find((item) => {
            const color = getProductColorName(item);
            return color === colorName;
          }) || current
        );
      });
    }

    const isOutOfStock = useMemo(
      () => !price || !!selected?.is_out_of_stock,
      [selected, price]
    );
    const addToCartLabelShop = useMemo(
      () => (isOutOfStock ? outOfStockLabel : addToCartLabel),
      [isOutOfStock, outOfStockLabel, addToCartLabel]
    );

    const stockLabel = useMemo(() => {
      if (isOutOfStock && selected?.out_of_stock_end && outOfStockShort) {
        const date = formatDate(
          selected?.out_of_stock_end,
          locale.localeTag,
          'short'
        );
        return replacePlaceholders<string>(outOfStockShort, [['%d', date]]);
      }
      return null;
    }, [isOutOfStock, selected, outOfStockShort]);

    const stockDescription = useMemo(() => {
      if (isOutOfStock && selected?.out_of_stock_end && outOfStockMessage) {
        const date = formatDate(selected?.out_of_stock_end, locale.localeTag);
        return replacePlaceholders<string>(outOfStockMessage, [['%d', date]]);
      }
      return null;
    }, [isOutOfStock, selected, outOfStockMessage]);

    const extra = (
      <>
        <div className={clsx('text__body', styles.description)}>
          {description}
        </div>
        <ProductSpecifications
          className={styles.specifications}
          pdp={pdp}
          variant={selected}
        />
      </>
    );

    return (
      <HeroPdpContainer alt={figure.alt} extra={extra} src={figure.src}>
        <ProductDetailImage
          alt={productImage.alt}
          src={productImage.src}
          priority
        />
        <Heading
          as="h1"
          className={clsx(styles.stylesShared)}
          variant="component-header-fluid"
        >
          {title}
        </Heading>
        <ProductDetailPrice>{price}</ProductDetailPrice>

        <ProductDetailColors
          callback={selectFromColor}
          className={styles.colors}
          label={colorLabel}
          selected={selectedColorName}
          variants={colors}
        />
        <ActionsPDP stockDescription={stockDescription} stockLabel={stockLabel}>
          <InputDropdownGroup>
            <InputSelect
              disabled={!hasPackSizes}
              hasNextSibling={true}
              label={packLabel}
              options={packSizes}
              selected={selectedPackSize}
              setSelected={selectFromPackSize}
            />
            <InputSelect
              disabled={!hasDimensions}
              hasPreviousSibling={true}
              label={dimensionLabel}
              options={dimensions}
              selected={selectedDimension}
              setSelected={selectFromDimension}
            />
          </InputDropdownGroup>

          {locale.isShopEnabled && (
            <ButtonLarge
              disabled={loading || isOutOfStock}
              onClick={onAddToCartClick}
              variant="filled"
              wide
            >
              {addToCartLabelShop}
            </ButtonLarge>
          )}
          {!locale.isShopEnabled && (
            <ButtonLargeLink href={findStoreHref} variant="filled" wide>
              {addToCartLabel}
            </ButtonLargeLink>
          )}
        </ActionsPDP>
      </HeroPdpContainer>
    );
  }
);
