import clsx from 'clsx';

import { getSizes } from '@string/utils/image/get-sizes';

import { StringImage } from '../string-image/string-image';

import styles from './hero-pdp-container.module.scss';

interface HeroPdpContainerProps {
  children?: React.ReactNode;
  extra?: React.ReactNode;
  src?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const HeroPdpContainer: React.FC<HeroPdpContainerProps> = ({
  children,
  src,
  alt = '',
  className,
  style,
  extra,
}) => {
  const sizes = getSizes([
    [0, '100vw'],
    [768, '50vw'],
    [1920, '950px'],
  ]);
  return (
    <section className={clsx('grid-wide', styles.root)}>
      <div className={clsx(styles.figure, className)} style={style}>
        {src && (
          <StringImage alt={alt} sizes={sizes} src={src || ''} fill priority />
        )}
      </div>
      <div className={styles.details}>{children}</div>
      <div className={styles.extra}>{extra}</div>
    </section>
  );
};
