import { useMemo } from 'react';

import { ProductEnriched } from '@string/types/portal-types/product-full-data';
import { ProductVariantEnriched } from '@string/types/portal-types/product-variant';

export const getProductImage = (
  product: ProductEnriched,
  selected?: ProductVariantEnriched | null
) => {
  let src = '';
  let alt = '';

  if (selected?.product_image?.url) {
    src = selected.product_image.url;
    alt = selected.product_image.description || alt;
  }

  if (!src && product?.product_image?.url) {
    src = product.product_image.url;
    alt = product.product_image.description || alt;
  }

  return {
    alt,
    src,
  };
};

export const useProductImage = (
  product: ProductEnriched,
  selected?: ProductVariantEnriched | null
) => {
  return useMemo(() => {
    return getProductImage(product, selected);
  }, [product, selected]);
};
