'use client';
import { Fragment, memo, useId, useMemo } from 'react';

import clsx from 'clsx';

import { useCart } from '@string/context/cart/use-cart';
import { useLocale } from '@string/hooks/use-locale/use-locale';
import { Material, ProductDetailFields, ProductLink } from '@string/types';
import type {
  ProductVariantArticle,
  ProductVariantEnriched,
} from '@string/types/portal-types/product-variant';
import { formatPrice } from '@string/utils/price/format-price';

import { Accordion } from '../../../accordion/accordion';
import { AccordionItem } from '../../../accordion-item/accordion-item';
import { ButtonTextLink } from '../../../button-text-link/button-text-link';

import styles from './product-specifications.module.scss';

interface Row {
  name: string | null | undefined;
  value: string | number | null | undefined;
}

interface ProductSpecificationsProps {
  variant: ProductVariantEnriched | null;
  pdp: ProductDetailFields;
  className?: string;
}

export const ProductSpecifications: React.FC<ProductSpecificationsProps> = memo(
  ({ pdp, variant, className }) => {
    const uid = useId();
    const locale = useLocale();
    const {
      care_label,
      depth,
      description,
      documents_label,
      height,
      material_label,
      material,
      max_weight,
      specifications_label,
      width,
      color_code,
    } = pdp;
    const { priceLabel } = useCart();

    const materials = useMemo(
      () => variant?.articles?.flatMap(({ materials }) => materials) || [],
      [variant]
    );
    const documents = useMemo(
      () =>
        variant?.articles
          .flatMap(({ documents }) => documents)
          .filter((document) => !!document?.url) || [],
      [variant]
    );

    function rowMapper(
      item: Row | undefined | null,
      index: number,
      all: Row[]
    ) {
      if (!item?.value) {
        return null;
      }
      return (
        item?.value && (
          <Fragment
            key={`ProductSpecifications-${uid}-${all.length}-row-${index}-${item?.name}-${item.value}`}
          >
            <dt>{item?.name || ''}</dt>
            <dd>{item.value}</dd>
          </Fragment>
        )
      );
    }

    const getSpecifications = (article: ProductVariantArticle) => {
      const rows: Row[] = [
        {
          name: description,
          value: article?.title,
        },
      ];

      if (article?.price?.price_inc_vat) {
        rows.push({
          name: priceLabel,
          value: formatPrice(article.price, locale.localeTag),
        });
      }
      if (typeof article?.dimensions?.width === 'number') {
        rows.push({
          name: width,
          value: article.dimensions.width + ' cm',
        });
      }
      if (typeof article?.dimensions?.height === 'number') {
        rows.push({
          name: height,
          value: article.dimensions.height + ' cm',
        });
      }
      if (typeof article?.dimensions?.depth === 'number') {
        rows.push({
          name: depth,
          value: article.dimensions.depth + ' cm',
        });
      }
      if (typeof article?.max_load === 'string') {
        rows.push({
          name: max_weight,
          value: article?.max_load,
        });
      }
      return rows;
    };

    const listClassName = clsx(
      'text__small',
      'text__small--light',
      styles.list
    );

    const specificationsMapper = (
      article: ProductVariantArticle,
      index: number
    ) => {
      const rows = getSpecifications(article);
      return (
        <dl
          key={`ProductSpecifications-${uid}-specifications-${article.ean}-${index}`}
          className={listClassName}
        >
          {rows?.map(rowMapper)}
        </dl>
      );
    };

    // TODO add microcopy in SB for "name" to make fields translatable
    const materialMapper = (materials: Material, index: number) => {
      const rows: Row[] = [];

      if (materials.description) {
        rows.push({
          name: material,
          value: materials.description,
        });
      }
      if (materials.ncs) {
        rows.push({
          name: color_code,
          value: materials.ncs,
        });
      }
      return (
        <dl
          key={`ProductSpecifications-${uid}-materials-${index}`}
          className={listClassName}
        >
          {rows?.map(rowMapper)}
        </dl>
      );
    };

    const careMapper = (materials: Material, index: number) => {
      if (!materials.care_maintenance?.description) {
        return null;
      }

      return (
        <div
          key={`ProductSpecifications-${uid}-care-${index}`}
          className={styles.care}
        >
          {materials.care_maintenance?.header && (
            <p className={clsx('text__small')}>
              {materials.care_maintenance?.header}
            </p>
          )}
          <div
            dangerouslySetInnerHTML={{
              __html: materials.care_maintenance.description,
            }}
            className="text__small--light text__small"
          />
        </div>
      );
    };

    const documentsMapper = (document: ProductLink | undefined | null) => {
      if (!document?.url) {
        return null;
      }
      const label = document?.name || document.url;
      return (
        <li
          key={`ProductSpecifications-${uid}-document-${label}-${document.url}`}
        >
          <ButtonTextLink className={styles.link} href={document.url}>
            {label}
          </ButtonTextLink>
        </li>
      );
    };

    return (
      <Accordion className={clsx(styles.root, className)} type="multiple">
        <AccordionItem
          key={`ProductSpecifications-${uid}-accordion-${0}-item`}
          className={styles.content}
          trigger={specifications_label}
          value="specifications"
        >
          {variant?.articles.map(specificationsMapper)}

        </AccordionItem>
        {!!materials.length && (
          <AccordionItem
            key={`ProductSpecifications-${uid}-accordion-${1}-item`}
            className={styles.content}
            trigger={material_label}
            value="materials"
          >
            {materials.map(materialMapper)}
          </AccordionItem>
        )}
        {!!materials.length && (
          <AccordionItem
            key={`ProductSpecifications-${uid}-accordion-${2}-item`}
            className={clsx(styles.content, styles.care)}
            trigger={care_label}
            value="care"
          >
            {materials.map(careMapper)}
          </AccordionItem>
        )}
        {!!documents.length && (
          <AccordionItem
            key={`ProductSpecifications-${uid}-accordion-${3}-item`}
            className={styles.content}
            trigger={documents_label}
            value="documents"
          >
            <ul className={styles.documents}>
              {documents?.map(documentsMapper)}
            </ul>
          </AccordionItem>
        )}
      </Accordion>
    );
  }
);
