import clsx from 'clsx';

import styles from './actions-pdp.module.scss';

interface ActionsPDPProps {
  children?: React.ReactNode;
  stockLabel?: string | null;
  stockDescription?: string | null;
  className?: string;
  style?: React.CSSProperties;
}

export const ActionsPDP: React.FC<ActionsPDPProps> = ({
  children,
  stockLabel,
  className,
  style,
  stockDescription,
}) => {
  return (
    <div className={clsx(styles.root, className)} style={style}>
      {children}
      {stockLabel && (
        <div
          className={clsx('text__small', styles.message)}
          title={stockDescription || ''}
        >
          {stockLabel}
        </div>
      )}
    </div>
  );
};
