import clsx from 'clsx';

import { ProductColor } from '@string/types';
import { getProductColorName, isDarkTheme } from '@string/utils';
import { inGroupNavigation } from '@string/utils/a11y/in-group-navigation';

import styles from './color-icon.module.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
function getColorIconStyle(color: ProductColor, selected: boolean) {
  const { primary_color, secondary_color } = color;
  const fallback = 'transparent';
  const style: Record<string, string> = {
    '--background-color': primary_color?.hex
      ? `#${primary_color.hex}`
      : fallback,
    '--background-image': primary_color?.image
      ? `url(${primary_color.image})`
      : 'none',
    '--border-color': selected ? 'var(--color-text, inherit)' : fallback,
  };

  if (secondary_color) {
    style['--background-color-secondary'] = secondary_color?.hex
      ? `#${secondary_color.hex}`
      : fallback;
    style['--background-image-secondary'] = secondary_color?.image
      ? `url(${secondary_color.image})`
      : 'none';
  }

  const primaryIsDark =
    primary_color?.hex && isDarkTheme('#' + primary_color.hex);
  const secondaryIsDark =
    secondary_color?.hex && isDarkTheme('#' + secondary_color.hex);

  if (!primaryIsDark || !secondaryIsDark) {
    let outlineColor = 'var(--color-grey-200, transparent)';
    if (primary_color.hex && primaryIsDark) {
      outlineColor = '#' + primary_color.hex;
    }
    if (
      !outlineColor.startsWith('#') &&
      secondary_color?.hex &&
      secondaryIsDark
    ) {
      outlineColor = '#' + secondary_color.hex;
    }
    style['--outline-color'] = outlineColor;
  }

  return style as React.CSSProperties;
}

export interface ColorIconProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'color'> {
  // onChange: React.ChangeEventHandler<HTMLInputElement>;

  color?: ProductColor;
}

export const ColorIcon: React.FC<ColorIconProps> = ({
  color,
  checked,
  ...rest
}) => {
  if (!color) {
    return null;
  }
  const name = getProductColorName(color);

  const style = getColorIconStyle(color, !!checked);

  return (
    <label
      className={styles.root}
      onKeyDown={inGroupNavigation}
      style={style as React.CSSProperties}
      tabIndex={0}
    >
      <input
        aria-label={name}
        checked={checked}
        className={styles.input}
        disabled={checked}
        name="color"
        type="radio"
        {...rest}
      />
      <span
        aria-hidden={true}
        className={clsx(styles.label, !!color?.secondary_color && styles.split)}
      />
    </label>
  );
};
