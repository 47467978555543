import { ProductColor, VariantEnriched } from '@string/types';

export function getProductColorName(
  color?: ProductColor | VariantEnriched | null
): string {
  if (!color?.primary_color?.name) {
    return '';
  }
  return Array.from(
    new Set([color.primary_color.name, color.secondary_color?.name])
  )
    .filter(Boolean)
    .join(' / ');
}
