'use client';

import { useId, useMemo } from 'react';

import { Root as VisuallyHidden } from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';

import { ProductColor, VariantEnriched } from '@string/types';
import { getProductColorName } from '@string/utils';

import { ColorIcon } from '../hero-pdp/partials/color-icon/color-icon';

import styles from './product-detail-colors.module.scss';

interface ProductDetailColorsProps {
  label?: string;
  callback?(key: string): void;
  selected?: string;
  className?: string;
  variants: VariantEnriched[];
}

export const ProductDetailColors: React.FC<ProductDetailColorsProps> = ({
  label,
  callback,
  selected,
  className,
  variants,
}) => {
  const uid = useId();

  const items = useMemo(
    () => variants?.filter((variant) => variant.primary_color).filter(Boolean),
    [variants]
  );

  function variantMapper(
    variant: VariantEnriched,
    index: number,
    all: VariantEnriched[]
  ) {
    if (!variant.primary_color) {
      return null;
    }

    const color: ProductColor = {
      primary_color: variant.primary_color,
      secondary_color: variant.secondary_color,
    };
    const name = getProductColorName(variant);

    if (index !== all.findIndex((item) => name === getProductColorName(item))) {
      return null;
    }
    const key = `ProductDetailColors-${uid}-color-${name}`;

    const onChange: React.ChangeEventHandler<HTMLInputElement> = () => {
      callback?.(variant.key);
    };
    const checked = !!selected && selected === name;
    return (
      <ColorIcon
        key={key}
        checked={checked}
        color={color}
        onChange={onChange}
      />
    );
  }

  return (
    <div className={clsx(styles.root, className)}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <div className={styles.list} role="radiogroup">
        {items.map(variantMapper)}
      </div>
      <div className={clsx('text__body', styles.selected)}>{selected}</div>
    </div>
  );
};
