import { RichtextStoryblok } from '@string/types/generated-storyblok-types';

type ReplacementKey = string | RegExp;
export type Replacements = Map<ReplacementKey, string>;
type ReplacementInput = [ReplacementKey, string];

type ReplacementsProp = Replacements | ReplacementInput[];

function replacePlaceholdersInString(
  input: string,
  replacements: ReplacementsProp
): string {
  let output = input;
  if (Array.isArray(replacements)) {
    replacements = new Map(replacements);
  }
  replacements.forEach((value, key) => {
    output = output.replace(key, value);
  });
  return output;
}

export function replacePlaceholdersInRichtext(
  input: RichtextStoryblok[] | undefined,
  replacements: ReplacementsProp
): RichtextStoryblok[] | undefined {
  if (!input) {
    return input;
  }
  if (Array.isArray(replacements)) {
    replacements = new Map(replacements);
  }
  return input.map((value) => {
    const output = { ...value };
    if ('content' in value && value.content?.length) {
      output.content = replacePlaceholdersInRichtext(
        value.content,
        replacements
      );
    }
    if ('text' in value && value.text) {
      output.text = replacePlaceholdersInString(value.text, replacements);
    }
    return output;
  });
}

export function isRichtextStoryblok(input: any): input is RichtextStoryblok {
  return 'type' in input;
}

export function replacePlaceholders<T = string | RichtextStoryblok>(
  input: T,
  replacements: ReplacementsProp
): T {
  if (!input) {
    return input;
  }
  if (typeof input === 'string') {
    return replacePlaceholdersInString(input, replacements) as T;
  }
  if (isRichtextStoryblok(input)) {
    // TODO: Implement recursive replacement
    return {
      ...input,
      content: replacePlaceholdersInRichtext(input.content, replacements),
    } as T;
  }
  return input as T;
}
