import clsx from 'clsx';

import type { HeaderTagName } from '@string/types';

export type HeadingVariant =
  | 'hero-header'
  | 'hero-header-fluid'
  | 'page-header'
  | 'component-header'
  | 'component-header-fluid'
  | 'sub-header';

export interface HeadingProps extends React.HTMLAttributes<HTMLElement> {
  as?: HeaderTagName;
  variant?: HeadingVariant;
}

export const Heading: React.FC<HeadingProps> = ({
  as = 'h2',
  className,
  variant = 'component-header',
  children,
  ...rest
}) => {
  const classNames = clsx(`text__${variant}`, className);
  switch (as) {
    case 'h1':
      return (
        <h1 className={classNames} {...rest}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={classNames} {...rest}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={classNames} {...rest}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={classNames} {...rest}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={classNames} {...rest}>
          {children}
        </h5>
      );
    case 'h6':
      return (
        <h6 className={classNames} {...rest}>
          {children}
        </h6>
      );
    default:
      return (
        <p className={classNames} {...rest}>
          {children}
        </p>
      );
  }
};
